<template>
    <div class="mine">   
        <div class="boxs">
            <div class="left">
                <div @click="navTo(index)" :class="index==mineIndex ? 'active':''"  v-for="(item,index) of $t('menu.about')" :key="index">{{item}}</div>
            </div>
            <div class="right">
                <router-view></router-view>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default{
    data(){
        return {
            mineIndex:0
        }
    },
    mounted() {
        console.log(this.$route.path)
        this.initActive();
    },
    methods:{
        initActive(){
            this.getPath(2)
        },
        getPath(type,index){
            var path = {
                0:'/About/introduction',
                1:'/About/brand',
                2:'/About/ShoppingInstructions',
                3:'/About/leaveAMessage',
                4:'/About/privacy'
            }
            if(type==1){
                return path[index]
            }else{
                Object.keys(path).forEach((index)=>{
                    if(path[index]==this.$route.path){
                       this.mineIndex = index
                    }
                })
            }
        },
          navTo(index){
            if(this.mineIndex==index){
                return
            }
            this.mineIndex=index;
            this.$router.push({path: this.getPath(1,index)})
          }
    }
  }
  </script>
  <style lang="scss">
  .mine{
    padding:40px 0px;
    .boxs{
        width: 1200px;
        margin: 0 auto;
        justify-content: space-between;
        display: flex;
        align-items: flex-start;
        .left{
            div{
                width: 280px;
                height: 40px;
                padding: 9px 0px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                cursor: pointer;
            }
            .active{
                background: #F8F8F8;
                transition: background .2s ease .2s;
            }
        }
    }
  }
  </style>